import { createRouter, createWebHistory } from "vue-router";

/* function loadView(view) {
  return () => import(`/src/${view}.vue`);
} */

const home = () => import(`../views/viewHome.vue`);
const signIn = () => import(`../views/viewSignin.vue`);
const settings = () => import(`../views/viewSettings.vue`);
const newPassword = () => import(`../views/viewNewPassword.vue`);
const search = () => import(`../views/viewSearch.vue`);
const search_results = () => import(`../views/viewSearchResults.vue`);
//const flow = () => import(`../views/viewFlow.vue`);
const templates = () => import(`../views/viewTemplates.vue`);
const template = () => import(`../views/viewTemplate.vue`);
const orgs = () => import(`../views/viewOrgs.vue`);
const createOrg = () => import(`../views/viewOrg.vue`);
const calculations = () => import(`../views/viewCalculations.vue`);
const calculation = () => import(`../views/viewCalculation.vue`);
const reports = () => import(`../views/viewReports.vue`);
const createReport = () => import(`../views/viewReport.vue`);
/* const propsTitle = () => import(`../components/propsTitle.vue`);
const cardTitle = () => import(`../components/cardTitle.vue`);
const cardTextbox = () => import(`../components/cardTextbox.vue`);
const cardNumbox = () => import(`../components/cardNumbox.vue`);
const cardUNumbox = () => import(`../components/cardNumboxWithUnits.vue`);
const unumbox = () => import(`../components/numboxWithUnits.vue`);
const variable = () => import(`../components/variable.vue`);
const propsVar = () => import(`../components/propsVariable.vue`);
const textArea = () => import(`../components/textArea.vue`);
const singleValued = () => import(`../components/singleValued.vue`);
const cardSingleValued = () => import(`../components/cardSingleValued.vue`);
const textBox = () => import(`../components/textbox.vue`);
const numbox = () => import(`../components/numbox.vue`);
const compCreate = () => import(`../components/compCreate.vue`);
const cardToggle = () => import(`../components/cardToggle.vue`);
const cardListbox = () => import(`../components/cardListbox.vue`);
const cardRadioGroup = () => import(`../components/cardRadioGroup.vue`);
const cardCheckbox = () => import(`../components/cardCheckbox.vue`);
const table = () => import(`../components/table.vue`);
const cardTabular = () => import(`../components/cardTabular.vue`);
const cardInputTab = () => import(`../components/cardInputTabular.vue`);
const toolbarCreate = () => import(`../components/toolbarCreate.vue`);
const cardToolbarCreate = () => import(`../components/cardToolbarCreate.vue`);
const graph2D = () => import(`../components/graph2D.vue`);
const graph3D = () => import(`../components/graph3D.vue`);
const cardGraph2D = () => import(`../components/cardGraph2D.vue`);
const cardGraph3D = () => import(`../components/cardGraph3D.vue`);
const compFileUpload = () => import(`../components/compFileUpload.vue`);
const listview = () => import(`../components/listview.vue`);
const cardListview = () => import(`../components/cardListview.vue`);
const textboxTrailingIcon = () =>
  import(`../components/textboxTrailingIcon.vue`);
const pagination = () => import(`../components/pagination.vue`); */
const loadingWave = () => import(`../components/loadingWave.vue`);
const loadingWaveSmall = () => import(`../components/loadingWaveSmall.vue`);
const fileEditor = () => import(`../components/fileEditor.vue`);
const treeBrowser = () => import(`../components/treeBrowser.vue`);
const cardTreeBrowser = () => import(`../components/cardTreeBrowser.vue`);
const minimalIDE = () => import(`../components/minimalIDE.vue`);
const cardExternalData = () => import(`../components/cardExternalData.vue`);
const cardTextarea = () => import(`../components/cardTextarea.vue`);
//const cardRepTemp = () => import(`../views/viewReportTemplating.vue`);
/* const gImg = () => import(`../components/graphImage.vue`);
const cardGImg = () => import(`../components/cardGraphImage.vue`); */
const visualiser = () => import(`../views/viewVisualiser.vue`);
const validation = () => import(`../views/viewValidation.vue`);
const validate = () => import(`../views/viewValidSub.vue`);
const cmsvalid = () => import(`../views/viewCmsValidation.vue`);
/* const butWttip = () => import(`../components/buttonWithTooltip.vue`);
const fileDwn = () => import(`../components/cardFileDownload.vue`); */

const routes = [
  {
    path: "/login",
    name: "signin",
    component: signIn,
  },
  {
    path: "/resetPass",
    name: "ResetPassword",
    component: newPassword,
  },
  {
    path: "/",
    name: "home",
    redirect: "/search",
    props: true,
    component: home,
    children: [
      {
        path: "/settings",
        name: "settings",
        component: settings,
      },
      {
        path: "/search",
        name: "search",
        component: search,
      },
      {
        path: "/search-results/:q",
        name: "search-results",
        component: search_results,
        props: true,
      },
      /* {
        path: "/flow/:id",
        name: "flow",
        component: flow,
        props: true,
      }, */
      {
        path: "/template/:id",
        name: "template",
        component: template,
        props: true,
      },
      {
        path: "/templates",
        name: "templates",
        component: templates,
      },
      {
        path: "/calculations",
        name: "calculations",
        component: calculations,
      },
      {
        path: "/calculation/:id",
        name: "calculation",
        component: calculation,
        props: true,
      },
      {
        path: "/reports",
        name: "reports",
        component: reports,
      },
      {
        path: "/report",
        name: "report",
        component: createReport,
      },
      {
        path: "/organisations",
        name: "orgs",
        component: orgs,
      },
      {
        path: "/organisation/:id",
        name: "organisation",
        component: createOrg,
        props: true,
      },
      {
        path: "/visualiser",
        name: "visualiser",
        component: visualiser,
      },
      {
        path: "/validation",
        name: "validation",
        component: validation,
      },
      {
        path: "/validate/:id",
        name: "validate",
        component: validate,
        props: true,
      },
      {
        path: "/validation/cms",
        name: "cms_validation",
        component: cmsvalid,
      },

      /* {
        path: "/props",
        name: "propsVar",
        component: propsVar,
      },
      {
        path: "/cardTitle",
        name: "cardtitle",
        component: cardTitle,
      },
      {
        path: "/cardTextbox",
        name: "cardTextbox",
        component: cardTextbox,
      },
      {
        path: "/cardNumbox",
        name: "cardNumbox",
        component: cardNumbox,
      },
      {
        path: "/cardUNumbox",
        name: "cardUNumbox",
        component: cardUNumbox,
      },
      {
        path: "/unumbox",
        name: "unumbox",
        component: unumbox,
      },
      {
        path: "/variable",
        name: "variable",
        component: variable,
      },
      {
        path: "/textArea",
        name: "txtArea",
        component: textArea,
      },
      {
        path: "/sv",
        name: "sv",
        component: singleValued,
      },
      {
        path: "/cardSV",
        name: "cardSV",
        component: cardSingleValued,
      },
      {
        path: "/textbox",
        name: "textbox",
        component: textBox,
      },
      {
        path: "/numbox",
        name: "numbox",
        component: numbox,
      },
      {
        path: "/create-comp",
        name: "createComponent",
        component: compCreate,
      },
      {
        path: "/cardToggle",
        name: "cardToggle",
        component: cardToggle,
      },
      {
        path: "/cardListbox",
        name: "cardlistbox",
        component: cardListbox,
      },
      {
        path: "/radioSelect",
        name: "radioGroup",
        component: cardRadioGroup,
      },
      {
        path: "/checkbox",
        name: "checkbox",
        component: cardCheckbox,
      },
      {
        path: "/table",
        name: "table",
        component: table,
      },
      {
        path: "/tabu",
        name: "cardTabular",
        component: cardTabular,
      },
      {
        path: "/inptabu",
        name: "cardInputTab",
        component: cardInputTab,
      },
      {
        path: "/createToolbar",
        name: "createToolbar",
        component: toolbarCreate,
      },
      {
        path: "/toolbarCreate",
        name: "toolbarCreate",
        component: cardToolbarCreate,
      },
      {
        path: "/graph2D",
        name: "graph2D",
        component: graph2D,
      },
      {
        path: "/graph3D",
        name: "graph3D",
        component: graph3D,
      },
      {
        path: "/cardG3D",
        name: "cardG3D",
        component: cardGraph3D,
      },
      {
        path: "/fileup",
        name: "fileup",
        component: compFileUpload,
      },
      {
        path: "/listview",
        name: "listview",
        component: listview,
      },
      {
        path: "/cardListview",
        name: "cardListview",
        component: cardListview,
      },
      {
        path: "/tbTrailIcon",
        name: "tbTrailIcon",
        component: textboxTrailingIcon,
      },
      {
        path: "/page",
        name: "page",
        component: pagination,
      }, */
      {
        path: "/loading",
        name: "loading",
        component: loadingWave,
      },
      {
        path: "/loadingSmall",
        name: "loadingSmall",
        component: loadingWaveSmall,
      },
      /* {
        path: "/editor",
        name: "editor",
        component: fileEditor,
      },
      {
        path: "/tree",
        name: "tree",
        component: treeBrowser,
      },
      {
        path: "/ctree",
        name: "ctree",
        component: cardTreeBrowser,
      },
      {
        path: "/ide",
        name: "ide",
        component: minimalIDE,
      },
      {
        path: "/cardExtData",
        name: "ced",
        component: cardExternalData,
      }, */
    ],
  },
  /*   {
    path: "/search",
    name: "search",
    component: search,
  }, */
  /* {
    path: "/create/:id",
    name: "create",
    component: create,
    props: true,
  }, */
  /*   {
    path: "/creator",
    name: "creator",
    component: creator,
  }, */
  /* {
    path: "/calculations",
    name: "calculations",
    component: calculations,
  }, */
  /* {
    path: "/calculation/:id",
    name: "calculation",
    component: calculation,
    props: true,
  }, */
  /* {
    path: "/props",
    name: "propsVar",
    component: propsVar,
  },
  {
    path: "/cardTitle",
    name: "cardtitle",
    component: cardTitle,
  },
  {
    path: "/cardTextbox",
    name: "cardTextbox",
    component: cardTextbox,
  },
  {
    path: "/cardNumbox",
    name: "cardNumbox",
    component: cardNumbox,
  },
  {
    path: "/cardUNumbox",
    name: "cardUNumbox",
    component: cardUNumbox,
  },
  {
    path: "/unumbox",
    name: "unumbox",
    component: unumbox,
  },
  {
    path: "/variable",
    name: "variable",
    component: variable,
  },
  {
    path: "/textArea",
    name: "txtArea",
    component: textArea,
  },
  {
    path: "/sv",
    name: "sv",
    component: singleValued,
  },
  {
    path: "/cardSV",
    name: "cardSV",
    component: cardSingleValued,
  },
  {
    path: "/textbox",
    name: "textbox",
    component: textBox,
  },
  {
    path: "/numbox",
    name: "numbox",
    component: numbox,
  },
  {
    path: "/createComp",
    name: "createComp",
    component: compCreate,
  },
  {
    path: "/cardToggle",
    name: "cardToggle",
    component: cardToggle,
  },
  {
    path: "/cardListbox",
    name: "cardlistbox",
    component: cardListbox,
  },
  {
    path: "/radioSelect",
    name: "radioGroup",
    component: cardRadioGroup,
  },
  {
    path: "/checkbox",
    name: "checkbox",
    component: cardCheckbox,
  },
  {
    path: "/table",
    name: "table",
    component: table,
  },
  {
    path: "/tabu",
    name: "cardTabular",
    component: cardTabular,
  },
  {
    path: "/createToolbar",
    name: "createToolbar",
    component: toolbarCreate,
  },
  {
    path: "/toolbarCreate",
    name: "toolbarCreate",
    component: cardToolbarCreate,
  },
  {
    path: "/graph2D",
    name: "graph2D",
    component: graph2D,
  },
  {
    path: "/graph3D",
    name: "graph3D",
    component: graph3D,
  },
  {
    path: "/cardG2D",
    name: "cardG2D",
    component: cardGraph2D,
  },
  {
    path: "/cardG3D",
    name: "cardG3D",
    component: cardGraph3D,
  },
  {
    path: "/fileup",
    name: "fileup",
    component: compFileUpload,
  },
  {
    path: "/listview",
    name: "listview",
    component: listview,
  },
  {
    path: "/cardListview",
    name: "cardListview",
    component: cardListview,
  },
  {
    path: "/tbTrailIcon",
    name: "tbTrailIcon",
    component: textboxTrailingIcon,
  },
  {
    path: "/page",
    name: "page",
    component: pagination,
  },
  {
    path: "/loading",
    name: "loading",
    component: loadingWave,
  },
  {
    path: "/editor",
    name: "editor",
    component: fileEditor,
  },
  {
    path: "/tree",
    name: "tree",
    component: treeBrowser,
  },
  {
    path: "/ctree",
    name: "ctree",
    component: cardTreeBrowser,
  },
  {
    path: "/ide",
    name: "ide",
    component: minimalIDE,
  },
  {
    path: "/cardExtData",
    name: "ced",
    component: cardExternalData,
  },
  {
    path: "/cardtxta",
    name: "ctxtarea",
    component: cardTextarea,
  },
  {
    path: "/repTemp",
    name: "repTemp",
    component: cardRepTemp,
  },
  {
    path: "/gimg",
    name: "gimg",
    component: gImg,
  },
  {
    path: "/cardgimg",
    name: "cardgimg",
    component: cardGImg,
  },
  {
    path: "/butWttip",
    name: "buttonWithTooltip",
    component: butWttip,
  },
  {
    path: "/filedwn",
    name: "fileDwn",
    component: fileDwn,
  }, */
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});
export default router;
